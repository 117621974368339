import React from 'react'
import Helmet from 'react-helmet'

function LinkingData ({ json }) {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          ...json
        })}
      </script>
    </Helmet>
  )
}

export default LinkingData
