import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled from '@emotion/styled'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'

const Root = styled.div`
  padding: 24px 0;
  @media ${mq.and(mobile)} {
    padding: 10px 0;
  }
`

const Container = styled.div`
  margin: 0 auto;
`
const List = styled.ol`
  display: flex;
  overflow: hidden;
`

const List_Item = styled.li`
  flex-shrink: 0;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  &:not(:first-of-type):before {
    content: '／';
    display: inline-block;
    margin: 0 0.5em;
  }

  &:last-of-type {
    flex-shrink: unset;
  }

  font-size: 14px;
  @media ${mq.and(mobile)} {
    font-size: 12px;
  }
`

const Link = styled(GatsbyLink)`
  line-height: 1.2;
  text-decoration: underline;
`

const Current = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`.withComponent('span')

const Component = props => {
  return (
    <Root className={props.className}>
      <Container>
        <List>
          {props.items.map((item, index) => (
            <List_Item key={index}>
              {index === props.items.length - 1
                ? (
                  <Current>{item.title}</Current>
                )
                : (
                  <Link to={item.path}>{item.title}</Link>
                )
              }
            </List_Item>
          ))}
        </List>
      </Container>
    </Root>
  )
}
export default Component
