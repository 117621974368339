export const flattenRoutes = (routes) => {
  const flattenRoutes = [{ title: 'サイトトップ', path: '/', position: 1 }]
  Object.keys(routes).forEach(key => {
    routes[key].forEach(route => {
      route.position = 2
      flattenRoutes.push({ title: route.title, path: route.path })
      if (!route.childLists) return
      route.childLists.forEach(child => {
        child.position = 3
        flattenRoutes.push({ title: child.title, path: child.path })
      })
    })
  })
  return flattenRoutes
}

export default {
  flattenRoutes
}
